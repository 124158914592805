.custom-dropdown {
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    left: 50% !important;
    transform: translate3d(-50%, 29%, 0px) !important;
    border: 1px solid #000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    min-width: auto;

    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 53.5%;
      margin-left: -12px;
      border-width: 0 8px 10px;
      border-style: solid;
      border-color: transparent transparent #000;
    }

    &::after {
      content: '';
      position: absolute;
      top: -8px;
      left: 53.5%;
      margin-left: -10px;
      border-width: 0 6px 8.5px;
      border-style: solid;
      border-color: transparent transparent #fff;
    }

    .dropdown-item {
      text-transform: uppercase;
      font-size: .75rem;
      font-weight: 600;
      @include transition(all .2s);

      &:hover {
        background-color: transparent;
        color: $color-primary;
        @include transform(translateY(3px));
      }
    }
  }

  &.add-to-calendar-dropdown {
    .dropdown-item {
      font-size: .7rem;
      padding-top: .25rem;
      padding-bottom: .25rem;
      text-align: left;
      position: relative;
      @include flexbox();
      @include align-items(center);

      &:hover {
        .icon-img {
          svg {
            fill: $color-primary;
          }
        }
      }

      .icon-img {
        width: 11px;
        height: 11px;
        top: -2px;
        margin-right: .5rem;
      }
    }
  }
}