.page-competition-info {
  background-color: #2aa37d;
  min-height: 100%;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 480px;
    background-image: url('/assets/images/competitions/img-info-bg-sm.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    @media (min-width: map-get($grid-breakpoints, md)) {
      background-image: url('/assets/images/competitions/img-info-bg-lg.png');
      height: 460px;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
      top: -5%;
      height: 600px;
    }

    @media (min-width: map-get($grid-breakpoints, xxl)) {
      top: -17%;
      height: 800px;
    }
  }

  .breadcrumbs-container {
    .breadcrumb-item {
      a {
        color: #fff !important;

        .icon-img {
          svg {
            fill: #fff;
          }
        }

        &:hover {
          .icon-img {
            svg {
              fill: #fff !important;
            }
          }
        }
      }
    }
  }

  .heading {
    padding: 3rem 0;
    text-align: center;
    z-index: 1;
    position: relative;

    .logo {
      width: 170px;
    }
  }

  .content-body {
    position: relative;
    text-align: center;
    color: #fff;
    z-index: 1;
  }

  .overview {
    .mastery {

      .mastery-item {

        figure {
          width: 150px;
        }

        h3 {
          padding: 0 2rem;
        }
      }
    }
  }

  .competition-parts {

    color: #000;

    .part {
      background-color: #fff;
      border: 1px solid #000;
      padding: 2rem;
      margin-bottom: 2rem;
      @include border-radius(1.25rem);

      @media (min-width: map-get($grid-breakpoints, sm)) {
        padding: 3rem;
      }

      @media (min-width: map-get($grid-breakpoints, lg)) {
        padding: 3rem 4rem;
      }

      .part-name {
        text-align: center;

        @media (min-width: map-get($grid-breakpoints, md)) {
          text-align: left;
        }
      }

      &:nth-child(even) {
        background-color: #F2F0EB;
      }

      &.bg-white {
        background-color: #fff;
      }

      ol {
        margin-bottom: 0;
      }
    }
  }

}