html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $font-primary;
  background-color: $body-background;
  word-break: break-word;
  @include flexbox();
  @include flex-direction(column);

  --bs-body-line-height: 1.65;

  a {
    cursor: pointer;
    text-decoration: none;
  }

  button {
    cursor: pointer;
  }

  img {
    width: 100%;
    max-width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-primary;
    font-weight: 700;
    line-height: 1.25;
  }

  h1 {
    font-size: calc(1.7rem + 1.5vw);

    @media (min-width: map-get($grid-breakpoints, md)) {
      font-size: calc(1.9rem + 1.15vw);
    }
  }

  h2 {
    font-size: calc(1.425rem + 0.9vw);

    @media (min-width: map-get($grid-breakpoints, md)) {
      font-size: calc(1.625rem + 0.9vw);
    }
  }

  h3 {
    font-size: calc(1.15rem + 0.6vw);

    @media (min-width: map-get($grid-breakpoints, md)) {
      font-size: calc(1.35rem + 0.6vw);
    }
  }

  p {
    margin-bottom: 0.75rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    width: 100%;
    max-width: 100%;
  }

  .inner-body {
    padding: 3rem 0;

    @media (min-width: $large-mobile-breakpoint) {
      padding: 3rem 1.25rem;
    }
  }

  .container {
    --bs-gutter-x: 2rem !important;
  }

  mark {
    padding: 0.05rem 0.125rem;
    background-color: $color-primary-pale;
  }

  &:lang(zh) {
    font-family: "Noto Sans TC", "微軟正黑體", "Microsoft JhengHei",
      "Apple LiGothic Medium", sans-serif;
  }

  &:lang(ja) {
    font-family: "Noto Sans JP", sans-serif;
  }

  &:lang(ko) {
    font-family: "NBG", "맑은 고딕", "Microsoft JhengHei", HelveticaNeue,
      DroidSans, Sans-serif, Helvetica;
  }

  &:lang(vi) {
    font-family: "SoDo Sans Vn", "SoDo Sans", sans-serif;
  }
}

.app-root,
.app-layout-container {
  @include flex(1);
  @include flexbox();
  @include flex-direction(column);
}

// ######### Utilities #######
.flex-1 {
  @include flex(1 !important);
}

.flex-md-1 {
  @media (min-width: map-get($grid-breakpoints, md)) {
    @include flex(1 !important);
  }
}

.flex-lg-1 {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    @include flex(1 !important);
  }
}

.flex-100 {
  @include flex(0 0 100% !important);
}

.flex-md-100 {
  @media (min-width: map-get($grid-breakpoints, md)) {
    @include flex(0 0 100% !important);
  }
}

.flex-lg-100 {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    @include flex(0 0 100% !important);
  }
}

.flex-auto {
  @include flex(0 0 auto !important);
}

// Icon img
.icon-img {
  @include inline-flex();
  @include align-items(center);
  @include justify-content(center);
  width: 1.5rem;
  height: 1.5rem;
  position: relative;

  svg,
  ::ng-deep svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

// Width
.w-sm-auto {
  @media (min-width: map-get($grid-breakpoints, sm)) {
    width: auto !important;
  }
}

.w-md-auto {
  @media (min-width: map-get($grid-breakpoints, md)) {
    width: auto !important;
  }
}

.w-lg-auto {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    width: auto !important;
  }
}

.mw-lg {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    max-width: 960px !important;
  }
}

// Text
.underline {
  text-decoration: underline !important;
}

.fs-small {
  font-size: 0.85rem !important;
}

.fs-smaller {
  font-size: 0.75rem !important;
}

small {
  font-size: 0.7rem !important;
}

// Margin & Padding
.mb-6 {
  margin-bottom: 4rem !important;
}

.pe-6 {
  padding-right: 4rem !important;
}

.pe-7 {
  padding-right: 5rem !important;
}

.pe-8 {
  padding-right: 6rem !important;
}

.px-lg-6 {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
}

.px-lg-7 {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
}

.px-lg-8 {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
}

.px-xl-8 {
  @media (min-width: map-get($grid-breakpoints, xl)) {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
}

// Ratio
.ratio-1-1 {
  aspect-ratio: 1 / 1 !important;
}

.ratio-16-9 {
  aspect-ratio: 16 / 9 !important;
}

// Background
.bg-primary-light {
  background-color: $primary-light !important;
}

.bg-attention {
  background-color: $color-attention !important;
}

.bg-live {
  background-color: $color-live !important;
}

.bg-body-background {
  background-color: $body-background !important;
}

// Text color
.text-primary-light {
  color: $color-primary-light !important;
}

.text-primary-dark {
  color: $color-primary-dark !important;
}

// Custom scrollbar
.custom-scrollbar {
  padding-right: 1.5rem !important;
  //scrollbar-width: thin;
  //scrollbar-color: $color-primary-pale $body-background;

  &::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 0;
    //background-color: #F5F5F5;
  }

  &::-webkit-scrollbar {
    width: 12px;
    //background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 0 2px #000;
    background-color: #b6cbd4;
  }
}

// Markdown editor
.md-editor {
  .btn.btn-default {
    min-width: auto;
  }
}

.quote1 {
  width: 450px !important;
}

.form-group.note-form-group.note-group-select-from-files {
  display: none;
}

// Toast
.toast-container {
  position: fixed !important;

  .ngx-toastr {
    padding-left: 20px;
    background-image: none;
  }

}

// Truncate
// Truncate single line
.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// Truncate multiline line
.truncate-line-clamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical; // this line will get removed by scss compiler, specifically declare this line in <head>

  &.lc-2 {
    -webkit-line-clamp: 2;
  }

  &.lc-3 {
    -webkit-line-clamp: 3;
  }

  &.lc-4 {
    -webkit-line-clamp: 4;
  }

  &.lc-5 {
    -webkit-line-clamp: 5;
  }
}

// Word break
.break-all {
  word-break: break-all;
}

// Image enlarge
// .clickable-image-wrapper {
//   @include inline-flex();
//   @include align-items(center);
//   @include justify-content(center);
//   position: relative;
//   cursor: pointer;

//   &:hover {
//     &::after {
//       content: '';
//       display: block;
//       width: 100%;
//       height: 100%;
//       top: 0;
//       left: 0;
//       position: absolute;
//       background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNNTA0Ljk2OSA0NzEuMDMxTDM3MC45NTkgMzM3LjAyM0MzOTkuMDg0IDMwMS41NDcgNDE2IDI1Ni43ODUgNDE2IDIwOEM0MTYgOTMuMTI1IDMyMi44NzUgMCAyMDggMFMwIDkzLjEyNSAwIDIwOFM5My4xMjUgNDE2IDIwOCA0MTZDMTU2Ljc4NSA0MTYgMzAxLjU0OSAzOTkuMDg2IDMzNy4wMjEgMzcwLjk2MUw0NzEuMDMxIDUwNC45NjlDNDc1LjcxOSA1MDkuNjU2IDQ4MS44NTkgNTEyIDQ4OCA1MTJTNTAwLjI4MSA1MDkuNjU2IDUwNC45NjkgNTA0Ljk2OUM1MTQuMzQ0IDQ5NS41OTQgNTE0LjM0NCA0ODAuNDA2IDUwNC45NjkgNDcxLjAzMVpNMjA4IDM2OEMxMTkuNzc1IDM2OCA0OCAyOTYuMjIzIDQ4IDIwOFMxMTkuNzc1IDQ4IDIwOCA0OFMzNjggMTE5Ljc3NyAzNjggMjA4UzI5Ni4yMjUgMzY4IDIwOCAzNjhaTTI4OC4wMDIgMTg0SDIzMS45OThWMTI4QzIzMS45OTggMTE0LjczOCAyMjEuMjU4IDEwNCAyMDggMTA0UzE4NC4wMDIgMTE0LjczOCAxODQuMDAyIDEyOFYxODRIMTI3Ljk5OEMxMTQuNzM4IDE4NCAxMDQgMTk0Ljc0MiAxMDQgMjA4UzExNC43MyAyMzIgMTI3Ljk5OCAyMzJIMTg0LjAwMlYyODhDMTg0LjAwMiAzMDEuMjU4IDE5NC43MzQgMzEyIDIwOCAzMTJDMjIxLjI1OCAzMTIgMjMxLjk5OCAzMDEuMjYyIDIzMS45OTggMjg4VjIzMkgyODguMDAyQzMwMS4yNjIgMjMyIDMxMiAyMjEuMjU4IDMxMiAyMDhTMzAxLjI2MiAxODQgMjg4LjAwMiAxODRaIiBmaWxsPSIjRkZGRkZGIi8+PC9zdmc+");
//       background-size: 25px;
//       background-repeat: no-repeat;
//       background-position: center;
//       background-color: rgba(0, 0, 0, .3);
//     }
//   }
// }

.clickable-enlarge-image {
  cursor: zoom-in;
}