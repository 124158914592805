.icon-img {
  @include inline-flex();
  @include align-items(center);
  @include justify-content(center);
  width: 1.5rem;
  height: 1.5rem;

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}