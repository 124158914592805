.btn {
  cursor: pointer;
  padding: .5rem 2rem;
  box-shadow: none;
  min-width: 190px;
  font-weight: 600;
  @include inline-flex();
  @include flex-wrap(wrap);
  @include align-items(center);
  @include justify-content(center);
  @include border-radius(5rem);

  .icon-img {
    width: 1.25rem;
    height: 1.25rem;
  }

  &.btn-primary {
    background-color: $color-primary-light;
    border: 1px solid #000;
    color: #000;

    &:hover {
      background-color: $color-primary-bright;
    }
  }

  &.btn-secondary {
    background-color: #f2f0eb;
    color: #000;

    &:hover {
      background-color: darken(#f2f0eb, 15%);
    }
  }

  &.btn-primary-live {
    background-color: $color-live;
    border: 1px solid #000;
    color: #fff;

    &:hover {
      background-color: lighten($color-live, 15%);
    }
  }

  &.btn-primary-recap {
    background-color: $color-recap;
    border: 1px solid #000;
    color: #000;

    &:hover {
      background-color: lighten($color-recap, 15%);
    }
  }

  &.btn-white {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;

    &:hover {
      background-color: #F2F0EB;
    }
  }

  &.btn-ghost-white {
    background-color: transparent;
    border: 0;
    color: #000;

    &:hover {
      background-color: #F2F0EB;
    }
  }

  &.btn-alert {
    background-color: $color-danger;
    border: 1px solid #000;
    color: #000;

    &:hover {
      background-color: lighten($color-danger, 15%);
    }
  }

  &.btn-circle {
    width: 40px;
    height: 40px;
    padding: 0;
    border: 0;
    overflow: hidden;
    min-width: 0;
    @include border-radius(20px);

    .icon-img {
      width: 1.25rem;
      height: 1.25rem;

      >* {
        @include inline-flex();
        @include align-items(center);
        @include justify-content(center);
        width: 100%;
        height: 100%;
        position: relative;
      }
    }
  }

  &.btn-square {
    width: 40px;
    height: 40px;
    padding: 0;
    border: 0;
    overflow: hidden;
    min-width: 0;
    @include border-radius(3px);

    .icon-img {
      width: 1.25rem;
      height: 1.25rem;

      >* {
        @include inline-flex();
        @include align-items(center);
        @include justify-content(center);
        width: 100%;
        height: 100%;
        position: relative;
      }
    }
  }

  &.btn-circle-outline {
    min-width: 0;
    border: 1px solid #000;
  }

  &.btn-close-lg {
    padding: 0;
    width: 40px;
    height: 40px;
    border: 0;
    min-width: 0;

    .icon-img {
      width: 90%;
      height: 90%;
    }
  }

  &.btn-close-modal {
    padding: 0;
    width: 34px;
    height: 34px;
    border: 0;
    min-width: 0;

    .icon-img {
      width: 90%;
      height: 90%;

      svg {
        fill: $gray-600;
      }
    }
  }

  &.btn-sm {
    padding: .35rem 1.25rem;
    font-size: .8rem;
    min-width: 125px;

    .icon-img {
      width: .8rem;
      height: .8rem;
    }
  }

  &:disabled,
  &.disabled {
    background-color: $gray-200;
    border-color: $gray-300;

    .icon-img {
      svg {
        circle {
          fill: $gray-600;
        }
      }
    }
  }
}