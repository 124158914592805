@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-LightItalic.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-BlackItalic.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-Regular.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-Bold.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-Italic.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-Black.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-Light.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-SemiBoldItalic.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

// Kalam
@font-face {
  font-family: 'Kalam';
  src: url('/assets/fonts/kalam/Kalam-Regular.woff2') format('woff2'),
    url('/assets/fonts/kalam/Kalam-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kalam';
  src: url('/assets/fonts/kalam/Kalam-Bold.woff2') format('woff2'),
    url('/assets/fonts/kalam/Kalam-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// Noto Sans TC
@font-face {
  font-family: 'Noto Sans TC';
  src: url('/assets/fonts/NotoSansTC/NotoSansTC-Bold.woff2') format('woff2'),
    url('/assets/fonts/NotoSansTC/NotoSansTC-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans TC';
  src: url('/assets/fonts/NotoSansTC/NotoSansTC-Light.woff2') format('woff2'),
    url('/assets/fonts/NotoSansTC/NotoSansTC-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Noto Sans TC';
  src: url('/assets/fonts/NotoSansTC/NotoSansTC-Medium.woff2') format('woff2'),
    url('/assets/fonts/NotoSansTC/NotoSansTC-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Noto Sans TC';
  src: url('/assets/fonts/NotoSansTC/NotoSansTC-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/NotoSansTC/NotoSansTC-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans TC';
  src: url('/assets/fonts/NotoSansTC/NotoSansTC-Regular.woff2') format('woff2'),
    url('/assets/fonts/NotoSansTC/NotoSansTC-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Noto Sans JP

@font-face {
  font-family: 'Noto Sans JP';
  src: url('/assets/fonts/NotoSansJP/NotoSansJP-Bold.woff2') format('woff2'),
    url('/assets/fonts/NotoSansJP/NotoSansJP-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans JP';
  src: url('/assets/fonts/NotoSansJP/NotoSansJP-Light.woff2') format('woff2'),
    url('/assets/fonts/NotoSansJP/NotoSansJP-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans JP';
  src: url('/assets/fonts/NotoSansJP/NotoSansJP-Medium.woff2') format('woff2'),
    url('/assets/fonts/NotoSansJP/NotoSansJP-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans JP';
  src: url('/assets/fonts/NotoSansJP/NotoSansJP-Regular.woff2') format('woff2'),
    url('/assets/fonts/NotoSansJP/NotoSansJP-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans JP';
  src: url('/assets/fonts/NotoSansJP/NotoSansJP-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/NotoSansJP/NotoSansJP-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

// NBG
@font-face {
  font-family: 'NBG';
  src: url('/assets/fonts/NBG/NanumBarunGothic.woff2') format('woff2'),
    url('/assets/fonts/NBG/NanumBarunGothic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// SoDoSans Vn
@font-face {
  font-family: 'SoDo Sans Vn';
  src: url('/assets/fonts/SoDoSansVn/SoDoSansVN-Bold.woff2') format('woff2'),
    url('/assets/fonts/SoDoSansVn/SoDoSansVN-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans Vn';
  src: url('/assets/fonts/SoDoSansVn/SoDoSansVN-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/SoDoSansVn/SoDoSansVN-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans Vn';
  src: url('/assets/fonts/SoDoSansVn/SoDoSansVN-Italic.woff2') format('woff2'),
    url('/assets/fonts/SoDoSansVn/SoDoSansVN-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans Vn';
  src: url('/assets/fonts/SoDoSansVn/SoDoSansVN-Light.woff2') format('woff2'),
    url('/assets/fonts/SoDoSansVn/SoDoSansVN-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans Vn';
  src: url('/assets/fonts/SoDoSansVn/SoDoSansVN-LightItalic.woff2') format('woff2'),
    url('/assets/fonts/SoDoSansVn/SoDoSansVN-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans Vn';
  src: url('/assets/fonts/SoDoSansVn/SoDoSansVN-Regular.woff2') format('woff2'),
    url('/assets/fonts/SoDoSansVn/SoDoSansVN-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans Vn';
  src: url('/assets/fonts/SoDoSansVn/SoDoSansVN-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/SoDoSansVn/SoDoSansVN-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans Vn';
  src: url('/assets/fonts/SoDoSansVn/SoDoSansVN-SemiBoldItalic.woff2') format('woff2'),
    url('/assets/fonts/SoDoSansVn/SoDoSansVN-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}