.form-group {
  position: relative;

  &+.form-group {
    margin-top: 1.15rem;
  }
}

.form-label {
  font-weight: 600;
  font-size: .8rem;
}

.form-control {
  position: relative;
  border-color: $gray-900;
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
}

.form-select {
  border-color: $gray-900;

  &:focus {
    box-shadow: none;
  }
}

.radio-group {
  .form-check {
    padding-left: 0;

    input[type="radio"] {
      height: auto;
      display: none;
    }

    label {
      position: relative;
      display: block;
      padding-left: 30px;
      line-height: 1.35;
      cursor: pointer;
      margin-bottom: 0;

      &:before {
        content: '';
        width: 18px;
        height: 18px;
        margin-right: 10px;
        position: absolute;
        left: 0;
        top: 2px;
        background-color: #aaa;
        @include border-radius (10px);
        border: 1px solid #333;
        background-color: #fff;
      }

      &:after {
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        left: 3px;
        top: 5px;
        background-color: $color-primary;
        @include border-radius (6px);
        display: none;
      }

      &:hover {
        &:after {
          background-color: $color-primary-pale;
          @include flexbox();
        }
      }
    }

    input[type="radio"]:checked+label:after {
      display: block;
      background-color: $color-primary;
    }

    &+.form-check {
      margin-top: .75rem;
    }
  }
}

.checkbox-group {
  .form-check {
    padding-left: 0;

    input[type="checkbox"] {
      height: auto;
      display: none;
    }

    label {
      position: relative;
      display: block;
      padding-left: 30px;
      line-height: 1.35;
      cursor: pointer;
      margin-bottom: 0;

      &:before {
        content: '';
        width: 18px;
        height: 18px;
        margin-right: 10px;
        position: absolute;
        left: 0;
        top: 1px;
        background-color: #aaa;
        @include border-radius (2px);
        border: 1px solid #333;
        background-color: #fff;
      }

      &:after {
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        left: 3px;
        top: 4px;
        background-color: $color-primary;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMThweCIgdmlld0JveD0iMCAtOTYwIDk2MCA5NjAiIHdpZHRoPSIxOHB4IiBmaWxsPSIjZmZmZmZmIj48cGF0aCBkPSJNMzgyLTI0MCAxNTQtNDY4bDU3LTU3IDE3MSAxNzEgMzY3LTM2NyA1NyA1Ny00MjQgNDI0WiIvPjwvc3ZnPg==");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        @include border-radius (1px);
        color: #fff;
        font-size: 10px;
        display: none;
        @include justify-content(center);
        @include align-items(center);
      }

      &:hover {
        &:after {
          background-color: $color-primary-pale;
          @include flexbox();
        }
      }
    }

    input[type="checkbox"]:checked+label:after {
      background-color: $color-primary;
      @include flexbox();
    }

    &+.form-check {
      margin-top: .75rem;
    }
  }
}

.invalid-message {
  display: block;
  font-size: .75rem;
  margin-top: .5rem;
  color: $color-danger;
}

input,
.form-control {
  &:disabled {
    background-color: #D2E0E2;
  }
}