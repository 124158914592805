// Fonts
$font-primary: 'SoDo Sans', sans-serif;

// Colors
$primary: #00754A;
$primary-light: #3ECEA9;
$primary-bright: #45E6BD;
$primary-dark: #025D65;
$color-primary: $primary;
$color-primary-light: $primary-light;
$color-primary-bright: $primary-bright;
$color-primary-dark: $primary-dark;
$color-primary-pale: #d4e9e2;
$color-attention: #FF6740;
$color-live: #FF0000;
$color-recap: #ff6740;
$danger : #ff6740;
$color-danger: $danger;
$body-background: #F9F9F9;
$color-black-apron-gold: #BDA882;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

$color-bap-yellow: #fac597;
$color-bap-green: #cee1ab;
$color-bap-red: #f7bac0;
$color-bap-blue: #a9d9dd;


// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px) !default;

$large-mobile-breakpoint: 415px !default;

$enable-shadows: true;

// Container
$container-max-widths: (
  sm: 540px,
  md: 750px,
  lg: 980px,
  xl: 1140px,
  xxl: 1320px) !default;