/**
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$sbux-community-web-primary: mat.define-palette(mat.$green-palette);
$sbux-community-web-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$sbux-community-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$sbux-community-web-theme: mat.define-light-theme((
  color: (
    primary: $sbux-community-web-primary,
    accent: $sbux-community-web-accent,
    warn: $sbux-community-web-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($sbux-community-web-theme); 
*/

/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'partials/variables';
@import 'bootstrap/scss/bootstrap';

/* Owl carousel */
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

/* Toastr */
@import '../../node_modules/ngx-toastr/toastr.css';

/* Summernote */
@import '../../node_modules/summernote/dist/summernote-lite.min.css';

/* Partials */
@import 'partials/mixins';
@import 'partials/fonts';
@import 'partials/global';
@import 'partials/buttons';
@import 'partials/icons';
@import 'partials/label';
@import 'partials/dropdown';
@import 'partials/forms';
@import 'partials/modal';
@import 'partials/list';
@import 'partials/theme';
@import 'partials/text-editor-contents';
@import 'partials/page-competition-info';