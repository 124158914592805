.label {
  @include inline-flex();
  @include align-items(center);
  @include justify-content(center);
  @include border-radius(6px);
  background-color: $color-primary;
  color: #fff;
  font-size: .7rem;
  font-weight: 500;
  padding: .4rem .5rem .35rem;
  line-height: 1;

  &.label-new {
    color: #000;
    font-weight: 700;
    background-color: #fff;
    @include box-shadow(0, 0, 0, 1px, #000, inset);
  }

  &.label-completed {
    color: #fff;
    background-color: $color-primary-light;
  }

  &.label-play-icon {
    color: #000;
    background-color: #fff;
    height: 1.75rem;
    width: 1.75rem;
    padding: 0;
    @include box-shadow(0, 0, 0, 1px, #000, inset);

    .icon-img {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  &.label-live {
    background-color: $color-live;
  }

  &.label-category {
    @include border-radius(0);
    height: 25px;
    min-width: 110px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
  }

  &.label-competitions {
    background-color: #ECC4C9;
  }

  &.label-events {
    background-color: #BFD48A;
  }

  &.label-learning {
    background-color: #EED350
  }

  &.label-workshops {
    background-color: #D2E0E2;
  }

  &.label-completed {
    background-color: $color-primary-light;
  }

  &.label-mandatory {
    background-color: transparent;
    color: #EF7A7B;
    font-weight: 700;
    height: 26px;
    padding: .4rem .75rem .35rem;
    @include border-radius(0);
    @include box-shadow(0, 0, 0, 1px, #EF7A7B, inset);
  }

  &.label-duration {
    background-color: #fff;
    color: #000;
    font-weight: 700;
    height: 26px;
    padding: .4rem .75rem .35rem;
    @include border-radius(0);
    @include box-shadow(0, 0, 0, 1px, #000, inset);
  }
}

.curated-data {
  @include flexbox();

  .label {
    &.label-curated-data {
      background-color: #fff;
      color: #000;
      font-weight: 700;
      height: 26px;
      padding: .4rem .75rem .35rem;
      @include border-radius(0);
      @include box-shadow(0, 0, 0, 1px, #000, inset);
    }

    &+.label {
      margin-left: -1px;
    }
  }
}

.label.label-bap-topic-item {
  height: 37px;
  font-size: .875rem;
  padding: 0 .75rem;
  @include border-radius(0);
  @include box-shadow(0, 0, 0, 1px, $color-black-apron-gold, inset);

  &+.label-bap-topic-item {
    margin-left: -1px;
  }

  &.label-bap-topic-item-code {
    background-color: #fff;
    color: #000;
    font-weight: 700;
  }

  &.label-bap-topic-item-points {
    background-color: #000;
    color: #fff;
    font-weight: 700;
  }

  &.label-bap-topic-item-status {
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;

    &.status-completed {
      background-color: $color-primary;
    }

    &.status-in-progress {
      background-color: #A7D1A3;
    }
  }
}