.modal-xl {
  @media (min-width: map-get($grid-breakpoints, xl)) {
    --bs-modal-width: 1024px;
  }

  @media (min-width: map-get($grid-breakpoints, xxl)) {
    --bs-modal-width: 1140px;
  }
}



.modal {
  .modal-dialog {
    padding-top: 50px;
    // margin-left: 1rem;
    // margin-right: 1rem;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      --bs-modal-margin: 1.25rem;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
      padding-top: 0;
    }
  }

  .modal-content {
    overflow: visible;
    background-color: transparent;
    border: 0;
    @include border-radius(1.25rem);
  }

  .modal-header {
    position: relative;

    .btn-close-modal {
      position: absolute;
      right: 0;
      top: -40px;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        right: -50px;
        top: 0;
      }

      .icon-img {
        svg {
          fill: rgba(255, 255, 255, .7);
        }
      }
    }
  }

  .modal-body {
    position: relative;
    padding: 0;
    background-color: transparent;
    @include transition(all .2s);
    @include border-radius(1.25rem);

    div {
      @include transition(all .2s);
    }

    .btn-close-modal {
      position: absolute;
      right: 0;
      top: -40px;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        right: -50px;
        top: 0;
      }

      .icon-img {
        svg {
          fill: rgba(255, 255, 255, .7);
        }
      }
    }

    .modal-inner-wrapper {
      background-color: transparent;
      @include border-radius(1.25rem);
      overflow: hidden;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        @include flexbox();
        @include flex-wrap(wrap);
      }
    }

    .modal-inner-header {
      background-color: transparent;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        @include flex(0 0 45%);
      }

      figure {
        aspect-ratio: 16 / 9;
        margin-bottom: 0;

        @media (min-width: map-get($grid-breakpoints, lg)) {
          aspect-ratio: 6 / 7;
          height: calc(100% + 1px);
          width: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .modal-inner-body {
      padding: 2rem 1.25rem;
      background-color: $gray-100;
      position: relative;
      margin-top: -1px;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        padding: 3rem 1.75rem 2rem;
        margin-top: 0;
        @include flex(1);
        @include flexbox();
        @include flex-wrap(wrap);
        @include flex-direction(column);
      }

      @media (min-width: map-get($grid-breakpoints, xl)) {
        padding: 3rem 2.25rem 2rem;
      }

      .inner-body-contents {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        @include flex(1);
      }

      .form-group {
        @media (min-width: map-get($grid-breakpoints, lg)) {
          width: 80%;
        }
      }

      .inner-body-footer {
        button {
          margin-bottom: .5rem;

          @media (min-width: map-get($grid-breakpoints, lg)) {
            margin-right: .75rem;
            min-width: 150px;
          }
        }

        small {
          font-size: .7rem;
        }
      }
    }

    .loading-wrapper {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      width: 100%;
      height: 100%;
      margin-bottom: 0;

      .spinner {
        width: 3rem;
        height: 3rem;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}


.person-bio-modal {

  // .modal-dialog {
  //   @media (min-width: map-get($grid-breakpoints, xl)) {
  //     --bs-modal-width: 900px;
  //   }
  // }

  // .modal-body {
  //   .btn-close-modal {
  //     position: absolute;
  //     right: 0;
  //     top: -40px;

  //     @media (min-width: map-get($grid-breakpoints, lg)) {
  //       right: -50px;
  //       top: 0;
  //     }

  //     .icon-img {
  //       svg {
  //         fill: rgba(255, 255, 255, .7);
  //       }
  //     }
  //   }
  // }
}

.person-text-bio-modal {
  .modal-dialog {
    padding-top: 50px;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      padding-top: 0;
    }
  }

}

.person-video-bio-modal,
.video-modal {
  .modal-content {
    border: 0;
    box-shadow: none;
    background-color: transparent;
    overflow: visible;
  }
}

.notifications-modal {
  .modal-dialog {
    padding-top: 0;
    // padding-bottom: 30px;

    // @media (min-width: map-get($grid-breakpoints, lg)) {
    //   padding-top: 0;
    //   padding-bottom: 0;
    // }
  }

  .modal-content {
    box-shadow: none;
  }
}

.modal-backdrop.show {
  @include opacity(0.75);
}

// Modal with theme colors
$theme-text-colors: (
  'yellow': #437D4C,
  'pink': #C63C31,
  'blue': #637598,
  'green': #437D4C
);

.theme-yellow {
  .modal-body {
    background: linear-gradient(to bottom, #E8D387, #A7D1A3);

    .body-header {

      .topic,
      h1 {
        color: map-get($theme-text-colors, 'yellow');
      }
    }

    .body-content {
      color: map-get($theme-text-colors, 'yellow') !important;

      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: map-get($theme-text-colors, 'yellow') !important;
      }
    }
  }

  .label-mandatory {
    background-color: #fff;
  }
}

.theme-pink {
  .modal-body {
    background: linear-gradient(to bottom, #F7BAC0, #EF7A7B);

    .body-header {

      .topic,
      h1 {
        color: map-get($theme-text-colors, 'pink');
      }
    }

    .body-content {
      color: map-get($theme-text-colors, 'pink') !important;

      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: map-get($theme-text-colors, 'pink') !important;
      }
    }
  }

  .label-mandatory {
    background-color: #fff;
  }
}

.theme-blue {
  .modal-body {
    background: linear-gradient(to bottom, #A9D9DD, #BBB0C6);

    .body-header {

      .topic,
      h1 {
        color: map-get($theme-text-colors, 'blue');
      }
    }

    .body-content {
      color: map-get($theme-text-colors, 'blue') !important;

      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: map-get($theme-text-colors, 'blue') !important;
      }
    }
  }

  .label-mandatory {
    background-color: #fff;
  }
}

.theme-green {
  .modal-body {
    background: linear-gradient(to bottom, #CEE1AB, #B9D4D2);

    .body-header {

      .topic,
      h1 {
        color: map-get($theme-text-colors, 'green');
      }
    }

    .body-content {
      color: map-get($theme-text-colors, 'green') !important;

      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: map-get($theme-text-colors, 'green') !important;
      }
    }
  }

  .label-mandatory {
    background-color: #fff;
  }
}