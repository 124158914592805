.dark-theme {
  header {
    background-color: rgba(0, 0, 0, .85) !important;

    .header-menu-toggle {
      .icon-img {
        svg {
          fill: #fff !important;
        }
      }
    }

    .header-logo-link {
      svg {
        fill: #fff !important;
      }
    }

    .header-tools {
      .btn-circle {
        background-color: #fff !important;
      }
    }

    .header-search {
      background-color: #fff !important;
    }
  }
}

.light-green-theme {
  header {
    background-color: $primary-light !important;

    // .header-menu-toggle {
    //   .icon-img {
    //     svg {
    //       fill: #fff !important;
    //     }
    //   }
    // }

    // .header-logo-link {
    //   svg {
    //     fill: #fff !important;
    //   }
    // }

    .header-tools {
      .btn-circle {
        background-color: #fff !important;
      }
    }

    .header-search {
      background-color: #fff !important;
    }
  }
}