.list-centerized {
  position: relative;

  ul,
  ol {
    @include flexbox();
    @include align-items(center);
    @include flex-direction(column);

    li {
      margin-bottom: .15rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}